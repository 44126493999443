
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { green, red } from '@ant-design/colors'
import { Empty } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { Member } from '@/types/Member'
import { Course } from '@/types/Course'
import api from '@/apis'

export default defineComponent({
  props: ['visible', 'uid'],
  components: {},
  setup() {
    const router = useRouter();
    const device = createDeviceDetector();

    /* Data */
    const assistantData = ref<Member>({} as Member);
    const coursesData = ref<Array<Course>>([]);
    
    /* Api */
    const getAssistantInfo = (async (uid: string) => {
      const response = await api.v1.assistant.findOne(uid);
      assistantData.value = response.data.result;
    });

    const getMemberBelongCourseList = (async (uid: string) => {
      const response = await api.v1.course.belongList(uid, {
        role: 3
      });
      coursesData.value = response.data.result;
    });
    
    return {
      router,
      device,

      /* Data */
      assistantData,
      coursesData,

      /* Api */
      getAssistantInfo,
      getMemberBelongCourseList,

      /* Antd */
      green,
      red,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  watch: {
    async visible() {
      if (this.visible) {
        await this.getAssistantInfo(this.uid);
        await this.getMemberBelongCourseList(this.uid);
        console.log(this.assistantData);
        console.log(this.coursesData);
      }
    }
  },
  methods: {
    /* Router */
    toCoursePage(id: string) {
      this.$emit('close');

      this.router.push({
        name: 'AppCoursePage',
        params: {
          course_id: id
        }
      });
    },

    /* Utils */
    calculateAge(birthdate: string) {
      const ageDifMs = Date.now() - new Date(birthdate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    
    /* Modal */
    onClose() {
      this.$emit('close');
    }
  }
})
