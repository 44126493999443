
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { UserOutlined, EllipsisOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAppStore } from '@/store/app'
import dayjs from 'dayjs'
import api from '@/apis'
import { Member, MemberUpdate } from '@/types/Member'
import CreateMemberModal from '@/components/ui/CreateMemberModal.vue'
import EditMemberModal from '@/components/ui/EditMemberModal.vue'
import MemberInfoDrawer from './components/MemberInfoDrawer.vue'

const columns = [{
  title: '助教編號',
  dataIndex: 'member_id',
  key: 'member_id'
}, {
  title: '姓名',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '性別',
  dataIndex: 'gender',
  key: 'gender',
}, {
  title: '生日',
  dataIndex: 'birthdate',
  key: 'birthdate',
}, {
  title: '聯絡電話',
  dataIndex: 'phone',
  key: 'phone',
}, {
  title: 'LINE ID',
  dataIndex: 'line_friend_id',
  key: 'line_friend_id'
}, {
  title: '操作',
  key: 'action'
}]


export default defineComponent({
  components: {
    CreateMemberModal,
    EditMemberModal,
    MemberInfoDrawer,
    UserOutlined,
    EllipsisOutlined,
    PlusOutlined,
    DownOutlined
  },
  setup() {
    const device = createDeviceDetector();

    /* Store */
    const appStore = useAppStore();

    /* Data */
    const assistantsData = ref<Array<Member>>([]);
    
    /* Status */
    const loading = ref<boolean>(false);

    /* Api */
    const getAssistantsData = (async () => {
      assistantsData.value = [];
      loading.value = true;
      
      const response = await api.v1.assistant.list();
      const result: Array<Member> = response.data.result;
      result.forEach(item => {
        item.key = item.uid;
        assistantsData.value.push(item)
      });

      loading.value = false;
    });

    const searchAssistantsData = (async () => {
      assistantsData.value = [];
      loading.value = true;

      const response = await api.v1.assistant.search({
        q: searchValue.value
      });
      const result: Array<Member> = response.data.result;
      result.forEach(item => {
        item.key = item.uid;
        assistantsData.value.push(item)
      });

      loading.value = false;
    });

    const updateOne = (async (uid: string, data: MemberUpdate) => {
      try {
        await api.v1.assistant.updateOne(uid, data);
        message.success('更新助教資料成功');
      } catch (error) {
        message.error('更新助教資料失敗，請稍後再試');
      }
    });

    const deleteOne = (async (uid: string) => {
      try {
        await api.v1.auth.deleteOne({ uid: uid });
        message.success('刪除助教成功');
      } catch (error) {
        message.error('刪除助教失敗，請稍後再試');
      } finally {
        await getAssistantsData();
      }
    });

    /* Mounted */
    onMounted(async () => {
      await getAssistantsData();
    });

    /* Table */
    const searchValue = ref<string>('');
    const genderOptions = reactive([{
      value: 'male',
      label: '先生'
    }, {
      value: 'female',
      label: '女士'
    }]);

    return {
      device,

      /* Store */
      appStore,

      /* Status */
      loading,

      /* Api */
      getAssistantsData,
      searchAssistantsData,
      updateOne,
      deleteOne,

      /* Data */
      columns,
      assistantsData,

      /* Table */
      searchValue,
      genderOptions,

      /* Antd */
      message
    }
  },
  data() {
    return {
      /* Create member modal */
      createMemberModal: {
        visible: false,
        role: 3
      },

      /* Edit member modal */
      editMemberModal: {
        visible: false,
        role: 3,
        data: {} as Member
      },

      /* Member info drawer */
      memberInfoDrawer: {
        visible: false,
        uid: ''
      }
    }
  },
  methods: {
    /* Utils */
    calculateAge(birthdate: string) {
      const ageDifMs = Date.now() - new Date(birthdate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    
    /* Create member modal */
    openCreateMemberModal() {
      this.createMemberModal.visible = true;
    },
    async handleCreateMemberModalConfirm() {
      await this.getAssistantsData();
      this.createMemberModal.visible = false;
    },
    handleCreateMemberModalCancel() {
      this.createMemberModal.visible = false;
    },

    /* Edit member modal */
    openEditMemberModal(data: Member) {
      this.editMemberModal.data = data;
      this.editMemberModal.visible = true;
    },
    async handleEditMemberModalConfirm() {
      const uid = this.editMemberModal.data['uid'];

      let updatedData = {} as MemberUpdate;
      updatedData.name = this.editMemberModal.data.name;
      updatedData.avatar = this.editMemberModal.data.avatar;
      updatedData.gender = this.editMemberModal.data.gender;
      updatedData.birthdate = dayjs(this.editMemberModal.data.birthdate).format('YYYY-MM-DD');
      updatedData.phone = this.editMemberModal.data.phone;
      updatedData.line_friend_id = this.editMemberModal.data.line_friend_id;
      updatedData.address = this.editMemberModal.data.address;

      await this.updateOne(uid, updatedData);
      await this.getAssistantsData();

      this.editMemberModal.visible = false;
    },
    handleEditMemberModalCancel() {
      this.editMemberModal.visible = false;
    },

    /* Member info drawer */
    openMemberInfoDrawer(uid: string) {
      this.memberInfoDrawer.uid = uid;
      this.memberInfoDrawer.visible = true;
    },
    handleMemberInfoDrawerClose() {
      this.memberInfoDrawer.visible = false;
    }
  }
})
